body {
    margin: 0;
    font-family: 'Lato', sans-serif!important;
    font-size: 1rem!important;
    font-weight: 400!important;
    line-height: 1.5!important;
    color: #212529!important;
    background-color: #fff!important;
}

.spaces-h3 {
    letter-spacing: inherit!important;
}

div#footer_bg li:after {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f105";
    position: absolute;
    left: -15px;
    top: 10px;
}

div#footer_bg li a {
    font-size: 16px;
    color: #fff;
    line-height: 35px;
}

div#footer_bg li {
    list-style: none;
    position: relative;
}

div#footer_bg ul {
    margin: 0;
}

@font-face {
    font-family: 'Neuton';
    src: url('../fonts/Neuton-Bold.eot');
    src: url('../fonts/Neuton-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Neuton-Bold.woff2') format('woff2'), url('../fonts/Neuton-Bold.woff') format('woff'), url('../fonts/Neuton-Bold.ttf') format('truetype'), url('../fonts/Neuton-Bold.svg#Neuton-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neuton';
    src: url('../fonts/Neuton-Regular.eot');
    src: url('../fonts/Neuton-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Neuton-Regular.woff2') format('woff2'), url('../fonts/Neuton-Regular.woff') format('woff'), url('../fonts/Neuton-Regular.ttf') format('truetype'), url('../fonts/Neuton-Regular.svg#Neuton-Regular') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

section.slider-section img {
    width: 100%;
    object-fit: cover;
}

.navbar div#navbarSupportedContent .nav-item:last-child a {
    padding: 32px 0px 32px 23px;
}

.navbar div#navbarSupportedContent2 .nav-item:last-child a {
    padding: 32px 0px 32px 23px;
}

div#navbarSupportedContent {
    justify-content: flex-end;
}

div#navbarSupportedContent2 {
    justify-content: flex-end;
}

a.navbar-brand {
    padding: 0;
}

#footer_coms .footer_message.f_message_left a:hover {
    color: #fff;
}

.navbar div#navbarSupportedContent .nav-link {
    color: rgba(255, 255, 255, 0.85)!important;
    font-size: 16px !important;
    font-weight: 400;
    cursor: pointer;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    text-align: center;
    float: left;
    z-index: 2000;
    position: relative;
    display: block;
    padding: 32px 23px 33px 23px;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
}

.navbar div#navbarSupportedContent2 .nav-link {
    color: rgba(255, 255, 255, 0.85)!important;
    font-size: 16px !important;
    font-weight: 400;
    cursor: pointer;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    text-align: center;
    float: left;
    z-index: 2000;
    position: relative;
    display: block;
    padding: 32px 23px 33px 23px;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
}

#header_container nav ul ul,
.widget_shopping_cart_content,
#search-form {
    border-top: 3px solid #ffffff;
}

ul.header_cart_dropdown,
.header_cart_dropdown .button,
#search-form,
#search-form.pop_search_form #ssform,
.child_pages_ctn li,
#header_container nav ul li ul,
#header_container nav ul li ul a {
    background: #ffffff;
}

#search-form {
    padding: 10px;
    background-color: #fff;
    right: 0;
    position: absolute;
    min-width: 250px;
    z-index: 1000;
    top: 53px;
    visibility: hidden;
    opacity: 0;
    transform: scale(0.8);
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -o-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transition: all ease-in-out .3s;
    -webkit-transition: all ease-in-out .3s;
    -moz-transition: all ease-in-out .3s;
    -o-transition: all ease-in-out .3s;
    -moz-box-shadow: 0 0px 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0px 6px rgb(0 0 0 / 10%);
    box-shadow: 0 0px 6px rgb(0 0 0 / 10%);
}

#hero {
    height: 100vh;
}

i.fa.fa-times #search-form {
    opacity: 1!important;
    visibility: visible !important;
    transform: scale(1) !important;
    -moz-transform: scale(1) !important;
    -webkit-transform: scale(1) !important;
    -o-transform: scale(1) !important;
    -ms-transform: scale(1) !important;
}

i.fa.fa-times {
    color: #a0a0a0;
}

.navbar div#navbarSupportedContent .nav-link:hover {
    color: black!important;
}

.navbar div#navbarSupportedContent2 .nav-link:hover {
    color: grey!important;
}

.navbar div#navbarSupportedContent .nav-item:last-child a i.fa {
    display: block;
    background-position: 0px 0px;
    background-size: 27px;
    min-height: 25px;
    min-width: 27px;
    cursor: pointer;
    position: relative;
    top: 5px;
}

.navbar div#navbarSupportedContent2 .nav-item:last-child a i.fa {
    display: block;
    background-position: 0px 0px;
    background-size: 27px;
    min-height: 25px;
    min-width: 27px;
    cursor: pointer;
    position: relative;
    top: 5px;
}

#search-form input[type=text] {
    border: 1px solid rgba(102, 102, 102, 0.1);
    float: left;
    margin: 0;
    width: 100%;
    padding: 9px 5px 9px 20px!important;
    border-radius: 5px;
    box-shadow: inset 0px 0px 6px 0px rgb(0 0 0 / 5%);
    -webkit-box-shadow: inset 0px 0px 6px 0px rgb(0 0 0 / 5%);
    -moz-box-shadow: inset 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
    -o-box-shadow: inset 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
    line-height: 20px;
    font-size: 14px;
    color: #a1b1bc;
    font: 400 13.3333px Arial;
    min-height: 40px;
}

#search-form input[type=submit] {
    background: none;
    background-position: 1px 1px;
    background-size: 27px;
    border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    -webkit-border-radius: 0px !important;
    min-height: 29px;
    min-width: 40px;
    margin: 0px;
    height: 40px;
    z-index: 1000;
    position: absolute;
    right: 10px;
    font-size: 16px;
    text-transform: uppercase;
    padding: 12px 10px;
    color: #ffffff;
    cursor: pointer;
    border: none;
    font-weight: 900;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

header.fixed {
    background: rgba(255, 255, 255, 0.95) !important;
    border-bottom: none!important;
    -moz-box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05) !important;
    -webkit-box-shadow: 0 2px 6px rgb(0 0 0 / 5%) !important;
    box-shadow: 0 2px 6px rgb(0 0 0 / 5%) !important;
    min-height: 90px;
    z-index: 99999999999;
}

.flex-container {
    display: flex;
    flex-direction: row;
    font-family: "Times New Roman";
    font-weight: 700;
    justify-content: center;
    padding: 0px!important;
    position: relative;
    z-index: 999;
}

.flex-container-div-2 {
    width: 525px;
}

.flex-container {
    margin-bottom: 20px;
}

section#heroen .maindiv {
    background-image: url(../img/about-img.png);
    margin: 0 auto;
    display: table;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
}

section#heroen {
    background-color: #000;
    padding: 90px 0px 50px!important;
}

.navbar div#navbarSupportedContent li.nav-item:hover ul.sub-menu.sf-js-enabled.sf-arrows {
    display: block!important;
}

.navbar div#navbarSupportedContent2 li.nav-item:hover ul.sub-menu.sf-js-enabled.sf-arrows {
    display: block!important;
}

ul.sub-menu.sf-js-enabled.sf-arrows {
    position: absolute;
    top: 90px;
    width: 250px;
    line-height: 20px;
    padding: 0 0 0 0;
    margin: 0;
    z-index: 5000;
    box-shadow: 0 8px 15px rgb(0 0 0 / 10%);
    border-top: 3px solid #ffffff;
    background: #ffffff;
}

ul.sub-menu.sf-js-enabled.sf-arrows li {
    width: 250px;
    border-right: none;
    font-weight: normal;
    text-transform: none;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    top: 0px;
    border-bottom: 1px solid #ededed;
    font-size: 15px;
}

ul.sub-menu.sf-js-enabled.sf-arrows li a {
    text-align: left;
    padding: 11px 14px 11px 14px;
    float: none;
    color: #a1b1bc;
    display: block;
    margin: 0;
    text-transform: none;
    font-weight: normal;
    border-bottom: none;
    border-top: none;
    text-shadow: none;
    border-right: none;
    border-radius: 0;
    line-height: normal;
    width: 100%;
    white-space: normal;
    font-family: Oswald;
    font-size: 15px;
    font-weight: 300;
    line-height: 14px;
}

ul.sub-menu.sf-js-enabled.sf-arrows li a:hover,
ul.sub-menu.sf-js-enabled.sf-arrows li.active a {
    background: #ededed;
    color: #2c3e50;
}

header.fixed ul.sub-menu.sf-js-enabled.sf-arrows {
    background: rgba(255, 255, 255, 0.95) !important;
    border: 0;
}

header.fixed ul.sub-menu.sf-js-enabled.sf-arrows li a {
    color: #3f3f3f;
}

a.navbar-brand img {
    max-width: 245px;
}

a:hover {
    text-decoration: none;
}

.maindiv h3 {
    font-size: 41px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0px;
    color: #fff;
    font-family: 'Neuton';
    text-align: center;
}

section#heroen .maindiv:after {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    height: 100%;
    content: "";
}

section#hero {
    position: relative;
}

section#hero:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.21);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

section#heroen .h3-text {
    font-size: 55px!important;
}

section#heroen .maindiv h5,
section#heroen .maindiv h6 {
    font-size: 25px;
    margin: 0;
    text-transform: uppercase;
    color: #fff;
    font-family: 'Neuton';
    text-align: center;
}

section#heroen .maindiv h6 {
    white-space: nowrap;
}

header.fixed .navbar div#navbarSupportedContent .nav-link {
    color: #a0a0a0 !important;
}

header.fixed .navbar div#navbarSupportedContent2 .nav-link {
    color: #a0a0a0 !important;
}

header.fixed .navbar div#navbarSupportedContent .nav-link:hover {
    color: black !important;
}

header.fixed .navbar div#navbarSupportedContent2 .nav-link:hover {
    color: black !important;
}

img.dark_logo.desktop_logo,
header.fixed img.white_logo.desktop_logo {
    display: none;
}

.flex-container-div-1,
.flex-container-div-2 {
    margin: 0px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
}

.flex-container-div-1 {
    width: 175px;
    font-weight: 700;
}

header.fixed img.dark_logo.desktop_logo {
    display: inline-block;
}

header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25)!important;
    z-index: 999;
}

nav.navbar.navbar-expand-lg {
    padding: 0;
}

.navbar div#navbarSupportedContent .nav-link i.fa.fa-angle-down {
    padding-left: 10px;
}

.navbar div#navbarSupportedContent2 .nav-link i.fa.fa-angle-down {
    padding-left: 10px;
}

div#footer_bg {
    background: #2b2b2b;
    /* color: #ccc; */
}

#footerr {
    width: 1210px;
    padding-bottom: 30px;
    overflow: auto;
    min-height: 250px;
    position: relative;
}

.footer_type_2 .widget_wrap {
    margin: 94px 30px 18px 0;
    width: 270px!important;
    float: left;
}

.footer_type_2 .widget {
    margin: 0px 0px 30px 0;
}

.textwidget p {
    margin-bottom: 17px;
}

p {
    line-height: 24px;
}

.footer_type_2 .widget h2 {
    font-size: 24px;
    text-transform: none;
    font-weight: 300;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 36px;
    color: #fcfcfc;
    font-family: Oswald;
}

img {
    max-width: 100%;
}

#instagram a:hover,
.f_si_type2 #f_social_icons #instagram,
.rd_si_big_squared #instagram,
.rd_si_big_rounded #instagram a,
.single_staff_social #instagram a,
.rd_staff_p06 .member-social-links #instagram a,
.thefox_social_widget_icons #instagram a {
    color: #ffffff;
    background-color: #517fa4;
}

#footerr .widget h2,
#footerr .widget_recent_entry h4 a {
    color: #fcfcfc;
}

.thefox_social_widget_icons div a {
    width: 37px;
    height: 37px;
    display: block;
    border-radius: 5px;
}

.thefox_social_widget_icons div {
    width: 37px!important;
    height: 37px !important;
    line-height: 37px;
    float: left;
    margin: 0 9.5px 10px 0px!important;
}

#facebook {
    margin: 0;
    position: relative;
    display: inline-block;
    zoom: 1;
    width: 30px;
    height: 30px;
    text-align: center;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#facebook a:hover,
.f_si_type2 #f_social_icons #facebook,
.rd_si_big_squared #facebook,
.rd_si_big_rounded #facebook a,
.sc-share-box #facebook a,
.single_staff_social #facebook a,
.rd_staff_p06 .member-social-links #facebook a,
.thefox_social_widget_icons #facebook a,
.share_icons_business .share-box #facebook a {
    color: #ffffff;
    background-color: #3c5a9a;
}

#twitter {
    margin: 0;
    position: relative;
    display: inline-block;
    *display: inline;
    zoom: 1;
    width: 30px;
    height: 30px;
    text-align: center;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#twitter a:hover,
.f_si_type2 #f_social_icons #twitter,
.rd_si_big_squared #twitter,
.rd_si_big_rounded #twitter a,
.sc-share-box #twitter a,
.single_staff_social #twitter a,
.rd_staff_p06 .member-social-links #twitter a,
.thefox_social_widget_icons #twitter a,
.share_icons_business .share-box #twitter a {
    color: #ffffff;
    background-color: #41d1da;
}

#yt {
    margin: 0;
    position: relative;
    display: inline-block;
    *display: inline;
    zoom: 1;
    width: 30px;
    height: 30px;
    text-align: center;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#yt a:hover,
.f_si_type2 #f_social_icons #yt,
.rd_si_big_squared #yt,
.rd_si_big_rounded #yt a,
.single_staff_social #yt a,
.rd_staff_p06 .member-social-links #yt a,
.thefox_social_widget_icons #yt a {
    color: #ffffff;
    background-color: #fe3432;
}

#lin {
    margin: 0;
    position: relative;
    display: inline-block;
    *display: inline;
    zoom: 1;
    width: 30px;
    height: 30px;
    text-align: center;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#lin a:hover,
.f_si_type2 #f_social_icons #lin,
.rd_si_big_squared #lin,
.rd_si_big_rounded #lin a,
.sc-share-box #lin a,
.single_staff_social #lin a,
.rd_staff_p06 .member-social-links #lin a,
.thefox_social_widget_icons #lin a,
.share_icons_business .share-box #lin a {
    color: #ffffff;
    background-color: #0173b2;
}

#instagram {
    margin: 0;
    position: relative;
    display: inline-block;
    *display: inline;
    zoom: 1;
    width: 30px;
    height: 30px;
    text-align: center;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#footerr .thefox_social_widget_icons a {
    color: #fff!important;
}

.thefox_social_widget_icons a i {
    color: #fff !important;
}

#footer_coms,
#footer_coms .menu a,
#f_social_icons a,
#footer_coms #to_top_img {
    color: #ffffff;
}

#footer_coms {
    text-align: right;
    padding: 30px 0 30px 0;
    background: #1e1e1e;
    border-top: 1px solid #282828;
    float: left;
    width: 100%;
}

#footer_coms .f_message_left {
    float: left;
    padding-right: 30px;
    height: 32px;
    line-height: 32px;
}

#footer_coms a {
    color: #0061b4;
}

#footerr,
#footerr .cat-item a {
    color: #ededed;
}

.widget.widget_text.contact-footer p strong {
    text-transform: uppercase;
}

.widget.widget_text.contact-footer p i {
    margin-right: 5px;
}

.widget.widget_text.contact-footer p.heading-cont {
    margin-bottom: 17px;
}

.widget.widget_text.contact-footer p {
    margin-bottom: 10px;
    line-height: 25px;
}

.widget.widget_text.contact-footer a {
    color: #fff;
}

.thefox_social_widget .fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
}


/* style Start 24March,21 */

header a.navbar-brand img.white_logo {
    max-width: 300px;
}

header a.navbar-brand img.dark_logo {
    max-width: 180px;
}

section.slider-section {
    position: relative;
}

.about-banner {
    position: relative;
}

.about-banner img {
    height: 500px;
    object-position: center top;
}

.about-banner h2 {
    position: absolute;
    left: 50%;
    width: 100%;
    top: 50%;
    color: #fff;
    font-weight: 400;
    font-size: 45px;
    z-index: 5;
    font-family: Oswald;
    line-height: 55px;
    letter-spacing: 0px;
    transform: translate(-50%, -50%);
    margin: 0;
    text-transform: uppercase;
}

section.slider-section::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    background: #000;
    width: 100%;
    height: 100%;
    opacity: 0.4;
}

.about-content,
.investment-tab {
    background-color: #f7f7f7;
    padding: 100px 0px;
    position: relative;
}

.about-content .content p {
    font-size: 16px;
    margin-bottom: 24px;
    color: #757575;
    line-height: 24px;
    white-space: pre-line;
}

.investment-tab .tab-content p:last-child {
    margin-bottom: 0px;
}

.about-content .content p:last-child {
    margin-bottom: 0px;
    white-space: pre-line;
}

.lending ul.list {
    list-style: none;
    padding: 0;
    margin-bottom: 24px;
}

.lending ul.list li {
    font-size: 16px;
    color: #757575;
    line-height: 24px;
}

.lending ul.list:last-child {
    margin-bottom: 0px;
}

.investment-tab {
    padding: 0px;
    position: relative;
    background: #fff;
}

.investment-tab::after {
    content: "";
    width: 45%;
    height: 100%;
    position: absolute;
    top: 0;
    background: #f7f7f7;
    left: 0;
}

.investment-tab .tab-list {
    position: relative;
    z-index: 999999;
    padding: 100px 0px;
}

.investment-tab .tab-list .nav-item {
    display: block;
    width: 100%;
}

.investment-tab .tab-list .nav-tabs .nav-link,
.investment-tab .tab-list .nav-tabs .nav-link:hover {
    border: 0px;
    border-bottom: 1px solid #ebebeb !important;
    padding: 15px 0px !important;
    border-radius: 0px !important;
    color: #999999 !important;
    font-size: 13px !important;
    text-transform: uppercase;
    font-weight: 400;
    background-color: transparent !important;
}

.investment-tab .tab-list .nav-tabs .nav-link::before {
    font-family: "FontAwesome";
    content: "\f0a9";
    float: left;
    margin-right: 12px;
    color: #0061b4;
}

.investment-tab .tab-list .nav-tabs .nav-link.active {
    color: #4285f4;
    background-color: transparent;
}

.investment-tab .tab-content {
    background: #fff;
    padding: 100px;
    height: 100%;
}

.investment-tab .tab-content p {
    font-size: 16px;
    margin-bottom: 24px;
    color: #757575;
    line-height: 24px;
    position: relative;
}

.investment-tab .tab-content p::before {
    font-family: "FontAwesome";
    content: "\f101";
    margin: 0 5px 0 -15px;
    font-size: 10px;
    font-weight: 300;
    position: absolute;
    top: 0;
    color: #000;
}

#footer .thefox_social_widget_icons a {
    color: #fff!important;
}

#footer_coms,
#footer_coms .menu a,
#f_social_icons a,
#footer_coms #to_top_img {
    color: #ffffff;
}

#footer_coms {
    text-align: right;
    padding: 30px 0 30px 0;
    background: #1e1e1e;
    border-top: 1px solid #282828;
    float: left;
    width: 100%;
}

#footer_coms .f_message_left {
    float: left;
    padding-right: 30px;
    height: 32px;
    line-height: 32px;
}

#footer_coms a {
    color: #0061b4;
}

#footer,
#footer .cat-item a {
    color: #ededed;
}

div#footer_bg li:after {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f105";
    position: absolute;
    left: -15px;
    top: 10px;
}

div#footer_bg li a {
    font-size: 16px;
    color: #fff;
    line-height: 35px;
}

div#footer_bg li {
    list-style: none;
    position: relative;
}

div#footer_bg ul {
    margin: 0;
}


/* style End 24March,21 */


/* style Start 25March,21 */

.porfolio {
    padding: 70px 0px;
    background-color: #fff;
    position: relative;
}

.porfolio .row {
    margin-bottom: 38px;
}

.porfolio .content {
    padding: 6px;
    border: 1px solid #ebebeb;
    height: 245px;
    position: relative;
    transition: 0.8s ease-in;
    overflow: hidden;
}

.porfolio .content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    filter: grayscale(0);
}

.porfolio .content .cont {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    color: #fff;
    z-index: 100;
}

.porfolio .content a {
    display: block;
    height: 100%;
    position: relative;
}

.porfolio .content a::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.35;
}

.porfolio .content .cont h3 {
    color: #fff;
    font-weight: 500;
    font-size: 32px;
    margin-bottom: 10px;
    font-family: Oswald;
    text-transform: uppercase;
}

.porfolio .content .cont h4 {
    color: #fff;
    font-weight: 300;
    font-size: 25px;
    margin-bottom: 0px;
    font-family: Oswald;
    text-transform: uppercase;
}

.porfolio .content:hover img {
    filter: grayscale(100%);
}

.map {
    width: 100%;
    height: 450px;
}


/* style End 25March,21 */


/* style Start 26March,21 */

.contact-form {
    padding: 76px 0px;
    background-color: #fff;
    position: relative;
}

.contact-form .left-block .add-block {
    padding: 35px 0px;
    /*border-bottom: 1px dashed;*/
}

.contact-form .left-block .add-block:first-child {
    padding-top: 0px;
}

.contact-form .left-block .add-block h5 {
    color: black;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 10px;
    font-family: Oswald;
}

.contact-form .left-block .add-block p {
    margin-bottom: 24px;
    font-size: 16px;
    color: black;
    line-height: 24px;
}

.contact-form .left-block .add-block p a {
    color: black;
}

.contact-form .left-block .add-block p i {
    margin-right: 10px;
    display: inline-block;
    color: inherit;
    font-size: 16px;
}

.contact-form .left-block .btn-block a {
    margin: 30px 0px;
    color: #ffffff;
    font-size: 14px;
    background-color: #0061b4 !important;
    border-color: #0061b4 !important;
    font-weight: 500;
    border-radius: 0px;
    text-transform: uppercase;
    box-shadow: none !important;
}

.contact-form .left-block .btn-block a:focus {
    box-shadow: none !important;
}

.contact-form .right-block .form-group {
    margin-bottom: 47px;
}

.contact-form .right-block .form-group .form-control {
    background-color: #fafafa;
    padding: 9px 12px 8px;
    border-color: #ddd;
    border-radius: 2px;
    font-size: 14px;
    color: black;
}

.contact-form .right-block .form-group select {
    color: #000 !important;
}

.contact-form .right-block .form-group .form-control::placeholder {
    color: #a1b1bc;
}

.contact-form .right-block .form-group .form-control:focus {
    box-shadow: none !important;
    border-color: #0061b4;
}

.contact-form .right-block .form-group textarea {
    resize: none;
}

.contact-form .right-block .btn-block {
    text-align: right;
}

.contact-form .right-block .btn-block .btn {
    background-color: #0061b4 !important;
    border-color: #0061b4 !important;
    color: #fff;
    font-size: 14px;
    padding: 9px 18px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: inherit;
    letter-spacing: 0;
    border-radius: 0px;
}

.contact-form .right-block .btn-block .btn:focus {
    box-shadow: none !important;
}

.contact-form .right-block .form-check .form-check-label {
    margin-right: 24px;
    color: #666666;
    font-size: 14px;
}

.contact-form .right-block .form-check .form-check-input {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    background-color: #fff;
    border-color: #aaa;
    vertical-align: middle;
    margin: 3px 5px 0 -21px;
}

.contact-form .right-block .form-group .icon-mail {
    position: absolute;
    right: 0;
    top: 0;
    cursor: text;
    line-height: 22px;
    font-size: 15px;
    margin: 9px 12px 7px;
    color: inherit;
    background-color: transparent;
    font: normal normal normal 15px/1 FontAwesome;
}


/*
.contact-form .right-block .form-group input[type="email"]{

}

*/


/* style End 26March,21 */


/* style Start 30March,21 */

.porfolio .port-list ul li a {
    font-size: 19px;
    line-height: 40px;
    font-weight: 400;
    border: 0 !important;
    color: #999999;
    text-transform: uppercase;
    padding: 8px 15px;
}

.porfolio .port-list ul li a:first-child {
    padding-left: 0px;
}

.porfolio .port-list ul li a.active {
    color: #010101 !important;
    font-weight: 700;
}

.porfolio .port-list .nav-tabs {
    border-bottom: 1px solid #ced4da !important;
}

.porfolio .port-list .nav-tabs li {
    margin-bottom: 0px !important;
}

.porfolio .row:first-child {
    margin-bottom: 20px;
}

.login .header h2 {
    font-size: 30px;
    font-family: sans-serif;
    color: #333;
    line-height: 33px;
    font-weight: 500;
}

.login .header p {
    font-weight: 400;
    line-height: 24px;
    color: #777;
    font-size: 20px;
    margin-bottom: 30px;
    font-family: sans-serif;
}

.login label {
    font-weight: 700;
    color: #333;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 21px;
    font-family: sans-serif;
}

.login label.form-check-label {
    font-weight: 400;
    cursor: pointer;
}

.login .form-group {
    margin-bottom: 15px;
}

.login .form-control:focus {
    border-color: #66afe9 !important;
    outline: 0 !important;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%) !important;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%) !important;
}

.login .form-control,
.login .form-control::placeholder {
    font-size: 14px;
}

.footer-cont {
    padding: 15px;
    ;
    border: 1px solid #c4c4c4;
}

.login .btn-block {
    margin-top: 30px;
}

.login .link-block {
    margin-bottom: 30px;
    margin-top: 5px;
}

.text-center.link-block a {
    color: #337ab7;
    text-decoration: none;
    font-size: 14px;
    font-family: sans-serif;
}

.text-center.link-block a:hover,
.text-center.link-block a:focus {
    color: #23527c;
    text-decoration: underline;
}

.text-center.link-block span {
    color: #777;
    margin: 0 4px;
}

.login .btn-block .btn {
    color: #ffffff;
    background-color: black !important;
    border-color: black !important;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-radius: 6px;
    font-family: sans-serif;
}

.login .content-body .btn-block .btn:focus {
    box-shadow: none !important;
}

.login .footer-cont p {
    color: #333;
    font-size: 15px;
    font-family: sans-serif;
}


/* style End 30March,21 */


/* style Start 31 March,21 */

.login .link-block p {
    color: #333;
    font-size: 14px;
    line-height: 21px;
    font-family: sans-serif;
    margin-bottom: 0px;
}

.register .btn-block {
    margin-top: 40px;
}

.register .btn-block .btn {
    padding: 14px 60px;
}

.single-portfolio {
    background-color: #ffffff;
    padding: 100px 0px 35px;
    position: relative;
}

.single-portfolio .rightblock-content h2 {
    font-size: 24px;
    line-height: 36px;
    font-family: Oswald;
    font-weight: 400;
    font-style: normal;
    color: #6b6b6b;
    text-transform: uppercase;
}

.single-portfolio .rightblock-content .details {
    margin: 35px 0px;
}

.single-portfolio .rightblock-content .details p {
    color: #757575;
    font-size: 16px;
    margin-bottom: 24px;
}

.single-portfolio .rightblock-content .details p strong {
    font-weight: 700;
}

.rightblock-content a.btn {
    /* background: linear-gradient( 135deg, #0061b4 0%,#0061b4 100%); */
    background-color: #0061b4;
    border-color: #0061b4;
    padding: 14px 18px;
    color: #fff;
    border-radius: 0px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    transition: all 0.4s linear;
}

.rightblock-content a.btn:hover,
.rightblock-content a.btn:active {
    background-color: #565656 !important;
    border-color: #565656 !important;
}

.rightblock-content a.btn:focus {
    box-shadow: none !important;
}

.account-list {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
}

.account-list ul {
    border: 0;
}

.account-list ul li a {
    font-size: 19px;
    line-height: 18px;
    font-weight: 400;
    border: 0 !important;
    color: #999999;
    padding: 22px 15px;
    text-transform: uppercase;
    background-color: #fff;
    min-width: 240px;
    max-width: 240px;
    text-align: center;
}

.account-list ul li a.ac2 {
    border-radius: 0px 10px 10px 0px;
}

.account-list ul li a.ac {
    border-radius: 10px 0px 0px 10px;
}

.account-list ul li a.active {
    color: #fff!important;
    background-color: #000!important;
}

.tabby-content {
    max-width: 600px;
    background-color: #f7f7f7;
    border-radius: 12px;
    margin: 100px auto;
    padding: 40px;
}

.single-portfolio .link-box {
    border: 1px solid #000000;
    margin: 0 2px;
}

.single-portfolio .link-box {
    position: relative;
}

.single-portfolio .link-box a::before {
    content: '\f07d';
    position: absolute;
    font-family: "FontAwesome";
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: center;
    width: 40px;
    height: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: #fff;
    background-color: #000;
    border-radius: 50%;
    transition: all 0.4s linear;
    z-index: 100;
    opacity: 0;
}

.single-portfolio .link-box a:hover::before {
    opacity: 1;
}


/* style End 31 March,21 */

.account-list ul li:last-child a.ac {
    border-radius: 0 10px 10px 0;
}


/* style banner */

.home-baneer-content {
    position: absolute;
    text-align: center;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 99;
    padding: 0px 15px;
    top: 40%;
}

.home-baneer-content h1 {
    margin-bottom: 25px;
    letter-spacing: 0px;
    font-weight: 300;
    font-size: 45px;
    font-family: "Oswald", sans-serif;
    color: #fff;
    text-transform: uppercase;
    line-height: 50px;
}

.home-baneer-content a {
    background-color: black;
    /*rgb(0, 97, 180);*/
    line-height: 17px;
    letter-spacing: 0px;
    font-weight: 500;
    font-size: 17px;
    color: #fff;
    padding: 12px 35px;
    text-transform: uppercase;
    margin-top: 20px;
    display: inline-block;
}

.home-baneer-content p {
    color: #fff;
    font-weight: 400;
    font-size: 20px;
}

body .login .link-block a {
    font-weight: 600;
    text-decoration: underline!important;
    color: #000;
}

header.contactts {
    position: initial;
    background: rgba(0, 0, 0);
}

header.contactts.fixed {
    position: fixed;
}


/* style banner */


/* style start 1 April,21 */

.single-portfolio .rowss {
    display: flex;
    flex-wrap: wrap;
}

.single-portfolio .rowss .img-box {
    flex: 0 0 20%;
    max-width: 20%;
}

.single-portfolio .link-box {
    border: 1px solid #000000;
    margin: 0 2px;
}

.single-portfolio .link-box {
    position: relative;
}

.single-portfolio .link-box a::before {
    background-size: 55%;
    position: absolute;
    font-family: "FontAwesome";
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: center;
    width: 50px;
    height: 50px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    transition: all 0.4s linear;
    z-index: 100;
    opacity: 0;
    content: "";
    background-image: url(/direct-zoom-icon.png);
    background-repeat: no-repeat;
    background-position: center center;
}

.single-portfolio .link-box a:hover::before {
    opacity: 1;
}

.single-portfolio .link-box a img {
    height: 160px;
    object-fit: cover;
    object-position: center;
    padding-bottom: 2px;
    width: 100%;
}

.lightbox {
    position: fixed;
    overflow: hidden !important;
    z-index: 9999999999;
}

.lightbox .modal-body {
    padding: 0;
    border-radius: 0;
}

.lightbox .modal-dialog {
    max-width: 1200px;
    margin: 100px auto;
}

.lightbox .modal-dialog .modal-body .carousel-item img {
    width: 100%;
    height: 750px;
    object-fit: cover;
    object-position: center;
}

.lightbox .close {
    position: fixed;
    opacity: 1;
    font-size: 50px;
    font-weight: 300;
    color: #fff;
    top: 0px;
    right: 40px;
    line-height: 70px;
    text-align: center;
    text-shadow: none;
    z-index: 5;
}

.lightbox .close:focus,
.lightbox .close:active {
    box-shadow: none;
    outline: 0 !important;
}

.lightbox .carousel-control {
    top: 100px;
    height: calc(100vh - 200px);
}

.lightbox .carousel-control:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
}

.lightbox .carousel-control i {
    font-size: 80px;
}

.porfolio .content .cont h4 {
    width: 100%;
}

section#hero a {
    z-index: 9999999;
}


/* style end 1 April,21 */

div#map-canvas,
div#map-canvas img {
    width: 100%;
    object-fit: cover;
}

.learn-more-btn:hover{
    color: grey !important;
}

/* style end 1 April,21 */


/*style 29 april */

.main-inner-log-sign-page {
    padding: 100px 15px 0px;
    margin: 0 auto;
    display: table;
    max-width: 550px;
}

.main-inner-log-sign-page .panel.panel-default {
    border-color: #ddd;
    margin-bottom: 20px;
    background-color: #fff;
    padding: 25px;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    border-style: solid;
    border-width: 1px;
    margin-top: 25px;
}

.login .link-block {
    margin-bottom: 0;
}

.login-hints {
    margin-top: 20px;
}

.login-hints.text-center a {
    color: #337ab7;
}

.login-tp-sec {
    margin: 70px 0px;
}

.lft-lg-img img {
    width: 100%;
}

.btn-black {
    background-color: black !important;
}

.anchor-containre {
    position: relative;
}

#heroen_anchor {
    position: absolute;
    top: -105px;
}

.btn-signup {
    margin-top: 10px !important;
}

/* testimonial style */
blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}

blockquote {
	position: relative;
	font-style: italic;
	font-size: 16px;
	font-weight:bold;
	line-height: 26px;
	margin:42px 0 42px 28px;
	padding:50px 0px 50px 50px;
}

blockquote p {
	line-height:26px;
	opacity:0.6;
}

blockquote:before {
	content:"";
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	background: black;
	width: 10px;
}

blockquote:after {
	/* content: "â€ž"; */
	font-family: "MS Serif", "New York", serif;
	position: absolute;
	top: 20px;
	right: 50px;
	font-size: 140px;
	opacity: 0.1;
}

.testimonial_line{
    border-top: dotted 2px;
}

.newsletter-title{
  font-size: 25px !important;
}

.span-content{
  font-size: 11px;
}


/* style end 29 april*/


/* responsive style start 1 April,21 */

@media only screen and (min-width:1200px) {
    .container {
        max-width: 1170px;
    }
}

@media only screen and (max-width:1300px) {
    #footerr {
        max-width: 100%;
    }
    .footer_type_2 .widget_wrap {
        margin: 94px 0px 18px 0;
        width: 25%!important;
        padding: 0px 15px;
    }
}

@media only screen and (max-width:1199px) {
    #footer_coms {
        padding: 20px 0 20px 0;
    }
    .footer_type_2 .widget_wrap {
        width: 23%!important;
    }
    .footer_type_2 .widget_wrap:nth-child(2) {
        width: 30%!important;
    }
    .widget.widget_text.contact-footer p i {
        margin-right: 2px;
    }
    .thefox_social_widget_icons div:last-child {
        margin: 0px!important;
    }
    .thefox_social_widget_icons div {
        width: 30px!important;
        height: 30px !important;
        margin: 0 8px 10px 0px!important;
    }
    .thefox_social_widget_icons div a {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #footerr {
        padding: 50px 0px;
    }
    .about-content,
    .investment-tab,
    .contact-form,
    .porfolio,
    section#hero1 {
        padding: 60px 0px;
    }
    .tabby-content {
        margin: 60px auto;
    }
    .about-banner img {
        height: 460px;
    }
    .about-banner h2 {
        font-size: 40px;
    }
    .map {
        height: 400px;
    }
    .contact-form .right-block .form-group {
        margin-bottom: 20px;
    }
    .login .header h2 {
        font-size: 28px;
    }
    .login .header p {
        font-size: 19px;
    }
    .investment-tab .tab-content {
        padding: 0px 40px;
    }
    .investment-tab .tab-list {
        padding: 0px;
    }
    .investment-tab .tab-list .nav-tabs .nav-link,
    .investment-tab .tab-list .nav-tabs .nav-link:hover {
        font-size: 12px;
    }
    .porfolio .content .cont h3 {
        font-size: 28px;
    }
    .porfolio .content .cont h4 {
        font-size: 22px;
    }
    .porfolio .tab-content .content {
        margin-bottom: 30px;
    }
    .single-portfolio {
        padding: 60px 0px 35px;
    }
    .single-portfolio .rightblock-content .details {
        margin: 30px 0px;
    }
    .single-portfolio .rightblock-content h2 {
        font-size: 22px;
    }
    .lightbox .modal-dialog {
        max-width: 700px;
    }
    .lightbox .modal-dialog .modal-body .carousel-item img {
        height: 500px;
    }
    .lightbox .carousel-control:hover {
        background-color: transparent;
    }
    .navbar div#navbarSupportedContent .nav-link {
        padding: 32px 20px 32px 20px;
    }
    .navbar div#navbarSupportedContent2 .nav-link {
        padding: 32px 20px 32px 20px;
    }
    .footer_type_2 .widget_wrap {
        margin: 0px;
    }
    .footer_type_2 .widget {
        margin: 0;
    }
}

@media only screen and (max-width:991px) {
    .flex-container {
        margin-bottom: 5px;
    }
    #hero a samp {
        left: 45%;
    }
    .home-baneer-content {
        top: 55%;
    }
    .home-baneer-content p {
        margin-bottom: 0;
    }
    .home-baneer-content h1 {
        margin-bottom: 5px;
        font-size: 30px;
    }
    #hero {
        height: 50vh;
    }
    .home-baneer-content {
        width: 100%;
    }
    .navbar-toggler {
        padding: 0.25rem 0.75rem 0.5em!important;
    }
    .footer_type_2 .widget_wrap {
        width: 29.33%!important;
    }
    .footer_type_2 .widget_wrap:nth-child(2) {
        width: 40%!important;
    }
    .footer_type_2 .widget_wrap:last-child {
        width: 100%!important;
        margin-top: 25px;
    }
    .fixed div#navbarSupportedContent {
        margin: 0;
        background: transparent;
    }
    .fixed div#navbarSupportedContent2 {
        margin: 0;
        background: transparent;
    }
    .fixed .navbar-toggler {
        border: 1px solid #a0a0a0;
    }
    button.navbar-toggler {
        margin-right: 15px;
    }
    header.fixed {
        min-height: inherit;
    }
    a.navbar-brand {
        padding: 10px 15px;
        margin: 0;
    }
    .navbar div#navbarSupportedContent .nav-link {
        color: #a0a0a0!important;
    }
    .navbar div#navbarSupportedContent2 .nav-link {
        color: #a0a0a0!important;
    }
    .fixed span.navbar-toggler-icon:after {
        color: #a0a0a0!important;
    }
    div#navbarSupportedContent {
        margin: 0;
        background: rgba(255, 255, 255, 0.95);
    }
    div#navbarSupportedContent2 {
        margin: 0;
        background: rgba(255, 255, 255, 0.95);
    }
    header .container {
        max-width: 100%;
        padding: 0;
    }
    header a.navbar-brand img {
        max-width: 175px;
    }
    span.navbar-toggler-icon:after {
        content: "\f0c9";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #fff;
        font-size: 30px;
    }
    .about-content,
    .investment-tab,
    .contact-form,
    .porfolio,
    section#hero1 {
        padding: 50px 0px;
    }
    .single-portfolio {
        padding: 50px 0px 35px;
    }
    .tabby-content {
        margin: 50px auto;
    }
    .about-banner img {
        height: 400px;
    }
    .about-content .content p {
        margin-bottom: 20px;
        font-size: 15px;
    }
    .investment-tab .tab-content p {
        margin-bottom: 20px;
        font-size: 15px
    }
    .lending ul.list li {
        font-size: 15px;
    }
    .lending ul.list {
        margin-bottom: 20px;
    }
    .contact-form .left-block .add-block p {
        font-size: 14px;
    }
    .login .header h2 {
        font-size: 24px;
    }
    .login .header p {
        font-size: 18px;
    }
    .investment-tab::after {
        width: 50%;
    }
    .investment-tab .tab-content {
        padding: 0px 15px;
    }
    .investment-tab .tab-list .nav-tabs .nav-link,
    .investment-tab .tab-list .nav-tabs .nav-link:hover {
        font-size: 11px;
    }
    .porfolio .content .cont h3 {
        font-size: 24px;
    }
    .porfolio .content .cont h4 {
        font-size: 20px;
    }
    .porfolio .port-list ul li a {
        font-size: 18px;
    }
    .single-portfolio .rowss .img-box {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
    .single-portfolio .rightblock-content .details p {
        font-size: 15px;
        margin-bottom: 20px;
    }
    .rightblock-content a.btn {
        font-size: 14px;
    }
    .single-portfolio .rightblock-content h2 {
        font-size: 20px;
    }
    .lightbox .carousel-control i {
        font-size: 70px;
    }
    .lightbox .modal-dialog {
        max-width: 550px;
    }
    .lightbox .modal-dialog .modal-body .carousel-item img {
        height: 400px;
    }
    .single-portfolio .link-box a img {
        width: 100%;
    }
    .login .header p {
        margin-bottom: 20px;
    }
    .map {
        height: 300px;
    }
    .navbar-toggler {
        border: 1px solid #fff;
    }
    .navbar-toggler:focus {
        box-shadow: none !important;
        outline: 0 !important;
    }
    .navbar div#navbarSupportedContent .nav-link,
    .navbar div#navbarSupportedContent .nav-item:last-child a {
        padding: 15px 15px;
    }
    .navbar div#navbarSupportedContent2 .nav-link,
    .navbar div#navbarSupportedContent2 .nav-item:last-child a {
        padding: 15px 15px;
    }
    .footer_type_2 .widget h2 {
        font-size: 20px;
    }
    div#footer_bg li a {
        font-size: 14px;
    }
    #footer_coms {
        padding: 25px 0 25px 0;
    }
    #footer_coms .f_message_left {
        font-size: 15px;
    }
    #heroen_anchor {
        position: absolute;
        top: -125px;
    }
}

@media all and (min-width: 992px) {
  	.navbar .nav-item .dropdown-menu{ display: none; }
  	.navbar .nav-item:hover .nav-link{   }
  	.navbar .nav-item:hover .dropdown-menu{ display: block; }
  	.navbar .nav-item .dropdown-menu{ margin-top:0; }
}

@media only screen and (max-width:767px) {
    .home-baneer-content a {
        display: inline-block!important;
    }
    section#hero a {
        display: none;
    }
    .flex-container-div-3 {
        width: auto;
    }
    .flex-container {
        padding-right: 0;
    }
    section#heroen .maindiv {
        display: block;
        height: auto;
    }
    .about-banner h2 {
        top: 60%;
    }
    #footer_coms {
        padding: 15px 0 15px 0;
    }
    #footer_coms .f_message_left {
        font-size: 13px;
        padding-right: 0;
    }
    #footer {
        padding: 25px 0px 50px;
    }
    section#heroen .flex-container-div-1 {
        width: 145px;
    }
    section#heroen .maindiv h3 {
        font-size: 25px!important;
        margin: 0;
    }
    section#heroen .maindiv h5,
    section#heroen .maindiv h6 {
        font-size: 15px!important;
    }
    section#heroen .flex-container-div-2 {
        width: 100%;
    }
    body section#heroen .h3-text {
        font-size: 40px!important;
    }
    div#footer_bg img.desktop_logo {
        max-width: 200px;
    }
    .footer_type_2 .widget_wrap {
        margin-top: 25px;
    }
    .footer_type_2 .widget_wrap,
    .footer_type_2 .widget_wrap:nth-child(2) {
        width: 100%!important;
    }
    div#footer_bg ul {
        margin: 0;
        padding-left: 15px;
    }
    .about-banner img {
        height: 350px;
    }
    .about-content,
    .investment-tab,
    .contact-form,
    .porfolio,
    section#hero1 {
        padding: 40px 0px;
    }
    .single-portfolio {
        padding: 40px 0px 35px;
    }
    .tabby-content {
        margin: 40px auto;
    }
    .map {
        height: 300px;
    }
    .contact-form .right-block .btn-block {
        text-align: center;
    }
    .register select.form-control {
        margin-bottom: 20px;
    }
    .investment-tab .tab-list .nav-tabs .nav-link,
    .investment-tab .tab-list .nav-tabs .nav-link:hover {
        font-size: 11px;
    }
    .porfolio .tab-content .content {
        margin-bottom: 20px;
    }
    .porfolio .content .cont h3 {
        font-size: 20px;
    }
    .porfolio .content .cont h4 {
        font-size: 18px;
    }
    .porfolio .port-list ul li a {
        font-size: 16px;
    }
    .single-portfolio .rowss .img-box {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .single-portfolio .rightblock-content .details {
        margin: 20px 0px;
    }
    .lightbox .carousel-control i {
        font-size: 60px;
    }
    .navbar div#navbarSupportedContent .nav-link {
        font-size: 14px !important;
    }
    .navbar div#navbarSupportedContent2 .nav-link {
        font-size: 14px !important;
    }
    header a.navbar-brand img {
        max-width: 200px;
    }
}

@media only screen and (max-width:575px) {
    .about-content,
    .investment-tab,
    .contact-form,
    .porfolio,
    section#heroen {
        padding: 30px 0px!important;
    }
    .single-portfolio {
        padding: 30px 0px 30px;
    }
    .tabby-content {
        margin: 30px auto;
    }
    .about-content .content p {
        font-size: 14px;
    }
    .lending ul.list li {
        font-size: 14px;
    }
    .about-banner img {
        height: 250px;
    }
    .about-banner h2 {
        font-size: 32px;
    }
    .map {
        height: 200px;
    }
    .contact-form .left-block .add-block h5 {
        font-size: 15px;
    }
    .contact-form .left-block .add-block {
        padding: 20px 0px;
    }
    .tabby-content {
        padding: 20px;
    }
    .account-list ul li a {
        min-width: 140px;
        max-width: 140px;
    }
    .login .header h2 {
        font-size: 20px;
    }
    .login .header p {
        font-size: 16px;
    }
    .login label {
        font-size: 13px;
    }
    .footer-cont {
        padding: 8px;
    }
    .login .footer-cont p {
        font-size: 11px;
    }
    .account-list ul li a {
        padding: 20px 15px;
        font-size: 16px;
    }
    .investment-tab::after {
        width: 0;
    }
    .investment-tab .tab-content {
        padding: 20px 15px 0px;
    }
    .investment-tab .tab-content p {
        font-size: 14px;
    }
    .porfolio .port-list ul li a {
        font-size: 13px;
    }
    .single-portfolio .rowss {
        margin-bottom: 20px;
    }
    .single-portfolio .rightblock-content .details p {
        font-size: 14px;
    }
    .single-portfolio .rightblock-content h2 {
        font-size: 18px;
    }
    .lightbox .modal-dialog {
        max-width: 100%;
    }
    .lightbox .carousel-control i {
        font-size: 50px;
    }
    .lightbox .modal-dialog .modal-body .carousel-item img {
        height: 270px;
    }
}

@media only screen and (max-width:360px) {
    .about-banner h2 {
        font-size: 25px;
    }
}


/* responsive style end 1 April,21 */
