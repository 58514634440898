
.flex-container {
    display: flex;
    flex-direction: row;
    font-family: "Times New Roman";
    font-weight: 700;
    justify-content: center;
    padding-right: 34px;
}
.flex-container-div-1 {
    width: 175px;
    margin: 1px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
    font-weight:bold;

}
.flex-container-div-2 {
    width: 525px;
    margin: 1px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
}
.flex-container-div-3 {
    width: 262px;
    margin: 1px;
    text-align: center;
    line-height: 75px;
    font-size: 30px;
}
.dark-blue{
    color: #3b7fa7;
}
.blue{
    color: #113055;
}
.maindiv{
    max-width: 530px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 335px;
}
.maindiv h3{
    font-size: 41px;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
    margin: -6px;
}
.maindiv h5{
    font-size: 25px;
    margin: 0;
    text-transform: uppercase;
}
.maindiv h6{
    font-size: 25px;
    margin: 0;
    text-transform: uppercase;
    white-space: nowrap;
}
.h5-text {
    font-weight: bold;
    font-size: 35px !important;
    margin: 0;
    text-transform: uppercase;
}
.h5-text-light{
    font-size: 25px !important;
    margin: 0;
    text-transform: uppercase;
}
.spaces-h3
{
    letter-spacing: 3px;
}
.h3-text{
    font-size: 55px !important;
    font-weight: bold;
    margin: 0;
    text-transform: uppercase;
}
