/**
* Template Name: Gp - v2.1.0
* Template URL: https://bootstrapmade.com/gp-free-multipurpose-html-bootstrap-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

body {
    font-family: "Roboto", sans-serif  !important;
    color: #444444;
    font-weight:900;
}
html { scroll-behavior: smooth; }
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.close {
    opacity: inherit !important;
    padding: 1rem 2rem !important;
    margin: -1rem -1rem -2rem auto !important;
}

.form-control {
    padding: 2.0rem 1rem;
    border: none;
}

.register-form-popup .form-control {
    padding: 1.5rem 1rem;
    border: none;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 980 !important;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.28);
}

.nav-tabs {
    border: none;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #000;
    /*border-radius: 10px 0px 0px 10px;*/
    padding: 22px 15px;
    font-weight: 900;
}

.btnContactSubmit {
    width: 50%;
    border-radius: 0.5rem;
    padding: 4.5%;
    color: #fff;
    background-color: #0062cc;
    border: none;
    cursor: pointer;
    background-color: black;
    margin-top: 4%;



}

.register-form {
    background-color: #f8f9fa;
    border-radius: 10px;
    padding-top: 50px;
    padding-right: 20px;
    padding-left: 20px;
    width: 75%;
    margin: 0 auto;
}

.register-form-popup {
    background-color: #f8f9fa;
    width: 100%;
    margin: 0 auto;
    border-radius: 0 0 20px 20px;

}

.register .nav-tabs .nav-link:hover {
    border: none;
}

.text-align {
    margin-top: -6%;
    text-align: center;
    padding-top: 10%;
    padding-right: 10%;
    padding-left: 10%;
    padding-bottom: 5%;
}

.form-new {
    padding-left: 50px;
    padding-right: 50px;
}

.register-heading {
    color: #000000;
    width: 59%;
    margin: 0 auto;
    margin-bottom: 5%;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    margin-top: 30px;
    text-align: center;
    line-height: 20px;

}

.register-heading h1 {
    margin-left: 21%;
    margin-bottom: 10%;
    color: #e9ecef;
}

.btnLoginSubmit {
    border: none;
    padding: 2%;
    width: 25%;
    cursor: pointer;
    background: #29abe2;
    color: #fff;
}

.btnForgetPwd {
    cursor: pointer;
    color: #263a41;
    text-decoration: underline;
    font-weight: 500;
    font-size: 14px ;
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background: url("https://i.postimg.cc/qv5LnCRT/phantom-partners-contact.jpg") top center;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.84);
    border-radius: 1rem;
    outline: 0;
    background-repeat: round;
}

.modal-content.sidebar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background: transparent;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.84);
    border-radius: 1rem;
    outline: 0;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    background-color: #fff;
    color: #000;
    padding: 22px 15px;
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
    font-size: 20px;
    /*border-radius: 0px 10px 10px 0px;*/
}

.fixed-top-class {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}

.fixed-top-1 {
    position: absolute;
    top: 65px;
    left: 0;
    z-index: 1030;
}

.nav-toggle {
    background-color: #000;
    border-radius: 0px 10px 10px 0px;
    padding-right: 30px;
    padding-left: 30px;
    border: black;
}

.space {
    padding-top: 50px;
}

.space-box {
    margin-bottom: 30px;
}

#header {
    transition: all 0.5s;
    z-index: 997;
    padding: 55px 0;
}

#header.header-scrolled,
#header.header-inner-pages {
    background: rgba(0, 0, 0, 0.8);
}

@media (max-width: 1600px) {
    #header .logo {
        margin-left: 60px !important;
    }

    .removeboderB.modal-lg, .modal-xl {
        max-width: 700px !important;
    }

    .register-heading {
        width: auto !important;
        margin-left: 0px !important;
    }

    .register-form {
        width: 90% !important;
        margin-left: 25px !important;
    }
}

@media (max-width: 1300px) {
    #header .logo {
        margin-left: 70px !important;
    }
}

@media (max-width: 992px) {
    #header .logo {
        margin-left: 100px !important;
    }

    .register-heading {
        width: unset;
       margin: 0 auto !important;
        font-size: 14px;
    }

    .form-control {
        padding: 1rem 0.5rem;
        font-size: 12px;
    }

    .register-form {
        width: 100% !important;
        margin-left: 0px !important;
        font-size: 12px;
    }

    .nav-tabs .nav-link {
        font-size: 15px;
    }

    .text-align {
        padding-right: 0%;
        padding-left: 0%
    }

    .nav {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    .form-new {
        padding-left: 11px;
        padding-right: 11px;
    }
}

@media (max-width: 990px) {
    .order-content {
        width: 49% !important;;
        float: left !important;
    }

    .order-content1 {
        margin-top: 0px !important;
        width: 49% !important;
        float: right !important;
    }

    .removeboderB.modal-lg, .modal-xl {
        max-width: 530px !important;
    }

    .space {
        padding-top: 50px !important;
    }

    .content h4 {
        width: 100% !important;
    }

    .logo img {
        width: 100%;
        max-width: 199px !important;
        height: auto;
    }
}

@media (max-width: 600px) {
    .container-fluid, .container-lg, .container-md, .container-xl {
        width: 100% !important;
    }

    .space {
        padding-top: 50px !important;
    }

    .order-content1 {
        margin-top: 5px !important;
    }

    #header .logo {
        margin-left: 10px !important;
    }

    .fixed-top-1 {
        top: 10px;
    }
}

#header .logo {
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.logo img {
    width: 100%;
    max-width: 125px;
    height: auto;
}




/* Get Startet Button */
.get-started-btn {
    color: #fff;
    border-radius: 8px;
    padding: 17px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 14px;
    display: inline-block;
    background-color: #000000;
    letter-spacing: 2px;
}

.one a {
    text-decoration: none;
}

.get-started-btn:hover {
    background: #000;
    color: #fff;
}

.one a:hover {
    text-decoration: none;
}

@media (max-width: 768px) {
    .get-started-btn {
        padding: 7px 20px 8px 20px;
    }
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/


#hero {
    width: 100%;
    height: 81vh;
  /*  background: url("https://i.postimg.cc/VN7QVcPr/featured-image1.jpg") top center; */
    background-size: cover;
    position: relative;

}

#hero1 {
    width: 100%;
    background: url("https://i.postimg.cc/rpq5qPSS/bg.jpg") top center;
    background-size: cover;
    position: relative;
    min-height: calc(100vh - 270px);
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }

    #hero1 {
        background-attachment: fixed;
    }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

 .removeboderB   .nav {
    padding-left: 50px !important;
    padding-right: 50px !important;
}

.nav-menu > ul {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.nav-menu > ul > li {
    position: relative;
    white-space: nowrap;
    padding: 10px 0 10px 28px;
    text-transform: uppercase;
}

.nav-menu a {
    display: block;
    position: relative;
    color: #fff;
    transition: 0.3s;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

.nav-menu a:hover,
.nav-menu .active > a,
.nav-menu li:hover > a {
    color: #fff;
    text-decoration: none;
}

.nav-menu .drop-down ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    padding: 0;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
}

.nav-menu .drop-down:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.nav-menu .drop-down li {
    min-width: 180px;
    position: relative;
}

.nav-menu .drop-down ul a {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: #151515;
}

.nav-menu .drop-down ul a:hover,
.nav-menu .drop-down ul .active > a,
.nav-menu .drop-down ul li:hover > a {
    color: #151515;
    background: #ffc451;
}

.nav-menu .drop-down > a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
    top: 0;
    left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
}

.nav-menu .drop-down .drop-down > a {
    padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
    content: "\eaa0";
    font-family: IcoFont;
    position: absolute;
    right: 15px;
}

@media (max-width: 1366px) {
    .nav-menu .drop-down .drop-down ul {
        left: -90%;
    }

    .nav-menu .drop-down .drop-down:hover > ul {
        left: -100%;
    }

    .nav-menu .drop-down .drop-down > a:after {
        content: "\ea9d";
    }
}

@media only screen and (max-width: 480px) and (min-width: 240px) {
    .nav-menu > ul {
        display: block;
    }

    .nav-menu ul {
        text-align: center;
    }

    .order-content {
        width: 100% !important;
    }

    .order-content1 {
        width: 100% !important;
    }

    .content h4 {
        width: 100% !important;
    }

     .removeboderB   .nav {
        padding-left: 0px;
        padding-right: 0px;
    }

    .register-form {
        width: 100%;
        margin-left: 0px;
    }

    .form-new {
        padding-left: 12px;
        padding-right: 11px;
    }
}

/*--------------------------------------------------------------
# main background
--------------------------------------------------------------*/
.content h4 {
    font-size: 21px;
    margin-bottom: .5rem;
    font-weight: 400;
    line-height: 1.5;
    width: 80%;
}

.order-content {
    background-color: #000;
    color: #fff;
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 43px;
    width: 45%;
}

.order-content h4 {
    font-size: 18px;
    line-height: 0px;
    color: white;
}

.order-content p {
    font-size: 12px;
    padding-top: 5px;
    margin-bottom: 0px;
}

.order-content1 {
    background-color: #000;
    color: #fff;
    border-radius: 10px;
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 43px;
    margin-top: 5px;
    width: 45%;
}

.order-content1 h4 {
    font-size: 18px;
    line-height: 0px;
    color: white;

}

.order-content1 p {
    font-size: 12px;
    padding-top: 5px;
    margin-bottom: 0px;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #000;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.card-title {

    margin-bottom: .75rem;
    font-size: 15px;
}

.card-title a {
    color: #fff;
}

.card-text {
    font-size: 12px;
}

.read-more {
    color: #fff;
    font-size: 12px;
}

.card-body a:hover {
    text-decoration: none;
}

.text-right {
    text-align: right;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.tag-row span {
    margin: 0 15px 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
    border-right: 1px solid #CCC;
    padding-right: 16px;
}

#footer {
    background: black;
    padding: 0 0 1px 0;
    color: #fff;

}

#footer .copyright {
    text-align: center;
    padding-top: 30px;
}

#footer .credits {
    padding-top: 20px;
    text-align: center;
    font-size: 13px;
    color: #d0d0d0;
    padding-bottom: 20px;
}

.credits a {
    color: #fff;
    text-decoration: none;
}

#footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #292929;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
    background: #ffc451;
    color: #151515;
    text-decoration: none;
}

.footersocial {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 5px;
}

.form-group p {
    color: #263a41;
    padding-top: 5px;
}

.form-group-center {
    text-align: center;
}

.form-group-center p {
    color: #263a41;
}

.form-group-center p a {
    color: #075a9e;
    text-decoration: underline;
}

.modal.fade.show .modal-dialog.modal-dialog-slideout {
    transform: translate(0, 0);
    display: inline-flex !important;
    align-items: stretch;
    -webkit-box-align: stretch;
    height: 100%;
}

.modal-dialog-slideout {
    min-height: 100%;
    margin: 0 0 0 auto;
    background-color: rgba(0, 0, 0, 0.7) !important;
}

.modal-center {
    color: white;
}

.modal-header {
    border-bottom: none;
}

.modal-body {
    text-align: center;
    /*padding: 0.5rem;*/
}

.modal-menu {
    border-top: none;
    padding: 2.75rem;
    height: 69px;
}

.nav-side-menu > ul {
    display: inline;
    justify-content: center;
    margin-top: 50px;
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-side-menu > ul > li {
    position: relative;
    white-space: nowrap;
    padding-left: 10px;
    border-left: 2px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 35px;
    text-transform: uppercase;
}

.nav-side-menu li a {
    color: rgba(255, 255, 255, 0.8);
}

.nav-side-menu li a:hover {
    color: #fff;
}

.nav-side-menu .active {
    border-left: 2px solid #Fff;
}

.close1 {
    float: right;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-shadow: 0 1px 0 #fff;
    background-color: transparent;
    border: none;
}

.container-fluid, .container-lg, .container-md, .container-xl {
    width: 75%;
}

.one1 {
    margin-bottom: 10px;
    margin-top: 20px;
}

.one1 a:hover {
    text-decoration: none;
}

button:focus {
    /* outline: 0px dotted; */
    outline: unset;
}

@media (min-width: 992px) {
    .removeboderB.modal-lg, .modal-xl {
        max-width: 900px;
    }
}

.modal-dialog-slideout {
    min-height: 100%;
    margin: 0 0 0 auto;
    background: #fff;
}

.modal.fade .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
}

.modal.fade.show .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    flex-flow: column;
}

.modal.fade.show .modal-dialog.modal-dialog-slideout .modal-body {
    overflow-y: auto;
    overflow-x: hidden;
}

.modal-dialog-slideout .modal-content {
    border: 0;
}

.modal-dialog-slideout .modal-header, .modal-dialog-slideout .modal-footer {
    height: 69px;
    display: block;
}

.modal-dialog-slideout .modal-header h5 {
    float: left;
}

.modal-dialog-slideout {
    min-height: 100%;
    margin: 0 auto 0 0;
    background: #fff;
}

@media screen and (min-width: 1600px) and (max-width: 1700px) {
    .login_popdesign_set_1 {
        max-width: 900px;
    }
}

.lopginmenul{
    border-radius: 10px 0 0 10px !important;
    width: 100%;
}
.lopginmenur{
    border-radius: 0 10px 10px 0 !important;
    width: 100%;
}

.lopginmenu{
    border-radius: 10px 10px 0px 0px !important;
    width: 95%;
    margin: 0 auto;
    color: #fff;
    background-color: #000;
    /* border-radius: 10px 0px 0px 10px; */
    padding: 22px 15px;
    font-size: 20px;

}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #fff !important;
    background-color: #000 !important;
    /* border-radius: 10px 0px 0px 10px; */
    padding: 22px 15px !important;
}
.form-group-center>p{
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 1px;
}
.Toastify{
    z-index: 90000000000;
}
.colorblack{
    background-color: #000 !important;
}
.pointercss
{
    cursor: pointer;
}

.removeboderB  .modal-header {
     border-bottom: none !important;
 }

.removeboderB  .modal-header span{
   font-size: 30px;
}

.removeboderB  .nav-tabs
{
    border: 0;
}

.removeboderB .modal-content{
    border-radius: 1rem;
}

.removeboderB .form-control {
    display: block;
    width: 100%;
    height: 40px !important;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .25rem;
    border: 0;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.removeboderB .form-group-center p{
    font-size: 1rem;
}

.removeboderB .register .form-control {
    display: block;
    width: 100%;
    height: 35px !important;
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .25rem;
    border: 0;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.sidebarmodel {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    height: 100%;
    transition: all 0.8s ease-in-out;
    z-index: 100;

}

.sidebarmodel a{
    color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
    font-size: 16px;
}
.sidebarmodel .modal-header{
    border-bottom: none;
    display: block;
    padding-bottom: 52px;
}
.sidebarmodel .modal-body{

    padding-left: 80px;
    padding-right: 80px;
}
.blocksldie{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background: rgba(26, 37, 47, 0.39);
    animation: fadeIn ease 2s;

}


.fadeout {
    color: #ffffff;
    font-size: large;
    font-family: helvetica;
    text-align: center;
    opacity: 0;
    -webkit-animation: fadeout 4s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeout 4s; /* Firefox < 16 */
    -ms-animation: fadeout 4s; /* Internet Explorer */
    -o-animation: fadeout 4s; /* Opera < 12.1 */
    animation: fadeout 4s;
}

@keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0;
    padding-top: 1rem;
}
@keyframes fadeOut {
    0% {opacity: 1;}
    100% {opacity: 0;}
}


#hero a span {
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: 35px;
    height: 35px;
    margin-left: -12px;
    border-left: 3px solid #fff;
    border-bottom: 3px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb04 3s infinite;
    animation: sdb04 3s infinite;
    box-sizing: border-box;
    font-weight: bold;
}

#hero a samp {
    position: absolute;
    bottom: 70px;
    left: 48%;
    box-sizing: border-box;
    color: white;
    font-size: 25px;
    font-family: Roboto;


}
@-webkit-keyframes sdb04 {
    0% {
        -webkit-transform: rotate(-45deg) translate(0, 0);
    }
    20% {
        -webkit-transform: rotate(-45deg) translate(-10px, 10px);
    }
    40% {
        -webkit-transform: rotate(-45deg) translate(0, 0);
    }
}
@keyframes sdb04 {
    0% {
        transform: rotate(-45deg) translate(0, 0);
    }
    20% {
        transform: rotate(-45deg) translate(-10px, 10px);
    }
    40% {
        transform: rotate(-45deg) translate(0, 0);
    }
}


/*--------------------------------------------------------------
# contact us start
--------------------------------------------------------------*/
.space-otherpage-top{
    padding-top: 55px;
}
.space-otherpage-center1{
    padding-top: 20px;
}
.lending-header{
    margin: 0 auto;
    width: 100%;
    text-align: center;
}
.lending-header p{
    margin: 0 auto;
    width: 40%;
    text-align: center;
    color: #626262;
    font-size: 13px;
    padding-bottom: 30px;
    padding-top: 10px;
}
.lending-header-h1{
    text-align: center;
    color: #444444;
    display:inline-block;
    border-bottom:3px solid #000;
    padding-bottom:2px;
    text-align: center;
    font-weight: bold;
    font-size: 40px;
}
.kw-section{
    background-color: #f8f9fa;
    border-radius: 15px;
    padding: 30px 60px;
    display: block;
    margin: 0 auto;
}
.kw-section h2{
    color: #000;
    font-size: 30px;
    padding-bottom: 20px;
}
.form-new-contact{
    padding: 0 25px;
    border: solid 1px #8080807a;
    font-size: 16px;
    font-weight: unset;
}
label {
    display: inline-block;
    margin-bottom: .5rem;
    margin-left: 10px;
    color: #000;
}
.btn-primary{
    color: #fff;
    background-color: #000;
    border-color: #000;
    margin: 0 auto;
    display: block;
    padding: 7px 20px;
}
.btn-primary:hover{
    color: #fff;
    background-color: #000;
    border-color: #000;
}
.blog2 .container{
    max-width: 144rem;
    text-align: right;
    display: block;
}
.custom-map-container{
    padding-bottom:20.25%;
    position:relative;
    height:350px;
}
.custom-map-container iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}
.kw-hero_content {
    background: #000000;
    color: #ffffff;
    display: inline-block;
    max-width: 450px;
    margin: 0 auto;
    padding: 25px;
    text-align: left;
    z-index: 2;
    position: absolute;
    padding-left: 95px;
    right: 5%;
    top: -15%;

}
h4.has-red-line:before {
    background: #808080;
    content: '';
    display: block;
    height: 2px;
    left: 38px;
    top: 69px;
    position: absolute;
    width: 7.2rem;
}
.kw-hero_content p{
    width: 100%;
}
.new-image a{
    color: #fff;
    padding-left: 10px;
}
.new-image{
    padding-top: 20px;
}
.aos-animate {
    color: white;
    font-size: 20px;
    font-family: Roboto;
    font-weight: bold;
}
.kw-hero_content_p{
    color: white;
    font-size: 16px;
    font-family: Roboto;

}
.new-image a{
    color: white;
    font-size: 16px;
    font-family: Roboto;
}
.btn-black{


    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    color: #fff ;
    background-color: #000;
    border-color: #000;
    margin: 0 auto;
    display: block;
    padding: 7px 20px;
}

.kw-section h2 {
    font-weight: bold;
}
.kw-section label {
    font-weight: bold;
    font-size: 16px;
}
/*--------------------------------------------------------------
#  contact us end
--------------------------------------------------------------*/

/*--------------------------------------------------------------
#  lending start
--------------------------------------------------------------*/
.col-img img{
    width: 180px;
}
.col-img{
    max-width: 16.5%;
}
/*--------------------------------------------------------------
#  lending end
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#  invest with us start
--------------------------------------------------------------*/
.space-otherpage-top{
    padding-top: 55px;
}
.space-otherpage-center1{
    padding-top: 20px;
}
.lending-header{
    margin: 0 auto;
    width: 100%;
    text-align: center;
}
.lending-header-h1{
    text-align: center;
    color: #444444;
    display:inline-block;
    border-bottom:3px solid #000;
    padding-bottom:2px;
    text-align: center;
}
.block-darker{
    background-color: #000;
    color: #fff;
}
.stragegy-eight{
    margin-right: 5px;
    margin-bottom: 10px;
}
.pad30{
    padding: 26px;
}
.pad30 p{
    margin: 0 0 20px 0;
    font-size: 12px;
}
.col-lg-6{
    padding-right: 0px;
    padding-left: 0px;
}
.block-about {
    background-image: url(https://i.postimg.cc/kDP7Lhh2/1.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
    margin-bottom: 10px;
}
.col-img-1 img{
    width: 270px;
}
.m-tp{
    margin-top: 15px;
}
hr {
    border: solid #adadad;
    border-width: 1px 0 0;
    clear: both;
    margin: 20px 0 50px;
    height: 0;
}
.container .four.columns{
    width: 25%;
}
.columns{
    float: left;
    display: inline;
    margin-right: 15px;
}
.block-inner h5 {
    margin-bottom: 10px;
    color: #626262;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1px;
    margin-left: 25px;
}
.block-inner ul {
    list-style: none outside;
}
.block-inner li {
    line-height: 20px;
    margin-bottom: 20px;
    position: relative;
    color: #626262;
    font-size: 14px;
}
.block-inner li:before {
    font-family: 'FontAwesome';
    content: '\f101';
    margin: 0 5px 0 -15px;
    font-size: 10px;
    font-weight: 300;
    position: absolute;
    top: 0;
    color: #000;
}
/*--------------------------------------------------------------
#  invest with us end
--------------------------------------------------------------*/
.why-us .card-img-top{
    height: 221px;
}


.fancybox-nav {
    position: fixed;
    top: 0;
}
.fancybox-nav span {
    visibility: visible;
}
.fancybox-next span {
    right: -60px;
}
.fancybox-prev span {
    left: -60px;
}
.fancybox-nav {
    position: absolute;
    top: 0px;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    /*background: url('../images/blank.gif') repeat scroll 0% 0% transparent;*/
    z-index: 8040;
    width: auto !important;
}
.space-otherpage-top {
    padding-top: 55px;
}
.lending-header {
    margin: 0 auto;
    width: 100%;
    text-align: center;
}
.lending-header-h1 {
    text-align: center;
    color: #444444;
    display: inline-block;
    border-bottom: 3px solid #444444;
    padding-bottom: 2px;
    text-align: center;
}
.portfolio li a.active {
    color: #010101;
    font-size: 19px;
    line-height: 18px;
    font-weight: bold;
    text-align: right;
    font-family: Roboto;
}
.portfolio li a {
    color: #787878;
    font-size: 19px;
    line-height: 18px;
    font-weight: bold;
    text-align: right;
    font-family: Roboto;
}

.space-otherpage-top .block-darker
{
    height: inherit;
}

@media only screen and (max-width: 480px) and (min-width: 240px) {
    .stragegy-eight {
        height: auto;
        margin-left: 10px;
        margin-right: 10px;
    }
    .block-about {
        margin-left: 10px;
        margin-right: 10px;
    }
    .col-img img {
        width: auto !important;
    }
}
@media only screen and (max-width: 768px)  {
    .portfolio li{
        margin: 0 auto;
    }
}
.pad30 {
    padding: 26px;
}
.pad30 p {
    margin: 0 0 20px 0;
    font-size: 12px;
}
.col-lg-6 {
    padding-right: 0px;
    padding-left: 0px;
}

.col-img img {
    width: 180px;
}
.portfolio li {
    padding-right: 20px;
}
.portfolio_lable_1 {
    font-size: 14px;
    line-height: 20px;
    color: #010101;
    font-weight: bold;
}
.portfolio_lable_2 {
    font-size: 12px;
    line-height: 20px;
    color: #626262;
    font-weight: 400;
}
.portfolio_image {
    width: 200px;
    height: 200px;
}
.bd-highlight {
    width: 200px;
    text-align: left;
    max-width: 219px !important;
}
@media (max-width: 992px) {
    .bd-highlight {
        width: 200px;
        text-align: center;
        max-width: 219px !important;
    }
}
.portfolio-image {
    position:relative;
    display:inline-block;
    margin:10px;
}
.portfolio-overlay {
    display:none;
}
.portfolio-image:hover .portfolio-overlay {
    width:100%;
    height:77%;
    background:rgba(0,0,0,0.8);
    position:absolute;
    top:0;
    left:0;
    display:inline-block;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    text-align:center;
    padding:12px;
    font-size:20px;
}
.portfolio-overlay-text {
    font-size: 14px !important;
    margin-bottom: 0 !important;
    text-transform: uppercase !important;
    color: #4984b8;
}
.portfolio-overlay-title{
    margin-top: 45%;
    color: #FFF !important;
    font-size: 14px !important;
    margin-bottom: 0 !important;
    text-transform: uppercase;
}
.fancybox-slide{

}
.fancybox-inner {
    max-width: 1000px;
    max-height: 600px;
    width: 85%;
    height: 90%;
    margin: auto;
}
.fancybox-button--zoom{
    display: none;
}
.fancybox-button--play{
    display: none;

}
.fancybox-button--thumbs{
    display: none;
}
.fancybox-slide--iframe{
    overflow: hidden !important;
}
.fancybox-slide{
    padding-top: 2px;
}
.hidefileuplodeonproile{
    opacity: 0;
    height: 1px;
    width: 1px;
}
.img-fluid-home{
    max-width: 91%;
    height: auto;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .kw-hero_content {
        background: #000000;
        color: #ffffff;
        display: inline-block;
        max-width: 450px;
        margin: 0 auto;
        padding: 25px;
        text-align: left;
        z-index: 2;
        position: absolute;
        padding-left: 95px;
        right: 9%;
        top: -22%;
    }
}
@media only screen and (max-device-width: 425px)  {
    .kw-hero_content {
        background: #000000;
        color: #ffffff;
        display: inline-block;
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
        padding: 25px;
        text-align: left;
        z-index: 2;
        position: inherit;
        padding-left: 95px;

    }
}


.custom-ragnge-slider .rangeslider__handle{
    background-color: #1a1010 !important;
    width: 25px;
    height: 25px;
    border: white solid;

}
.listing-image {
    width: 500px;
    height:300px;
}
.portfolio-image {
    position:relative;
    display:inline-block;
    margin:10px;
}
.listing-overlay {
    display:none;
}

.listing-image .listing-overlay {
    width: 500px;
    height:300px;
    background:rgba(0,0,0,0.1);
    color: #e7e5e5;
    position:absolute;
    top: 8px;
    left: 20px;
    display:inline-block;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    text-align:center;
    padding:12px;
    font-size:20px;
    vertical-align: middle;
}
.listing-image-right .listing-overlay {
    width: 500px;
    height:300px;
    background:rgba(0,0,0,0.1);
    color: #e7e5e5;
    position:absolute;
    top: 8px;
    right: 20px;
    display:inline-block;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    text-align:center;
    padding:12px;
    font-size:20px;
    vertical-align: middle;
}


.listing-overlay-title{
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -9px;
    font-size: 30px !important;
}
.listing-overlay-text {
    font-size: 14px !important;
    margin-bottom: 0 !important;
    text-transform: uppercase !important;
    color: #4984b8;
}
.listing-border{
    border: 1px solid #434349 !important;
}

.listings-filter .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0.5rem;
    padding-bottom: 0.25rem;
    min-height: 130px;
    background: #f6f4f4;
}

.custom-ragnge-slider .rangeslider__handle-label{
    display: none;
}
.rangeslider__handle:after{
    display: none;
}
#custom-ragnge-slider .rangeslider__fill{
    background-color: #1a1010 !important;
}




/*--------------------------------------------------------------
#  listing start
--------------------------------------------------------------*/
.solid{
    border-style: solid ;
    border-width: 0px 0px 1px 0px;
    border-color: #d9d8d9;
    padding-top: 2px;
    margin-bottom: 5px;
}
.Property-text{
    width: 100%;
    float: left;
    padding-left: 5px;
}

.Property-text1 p{
    margin-top: 0px;
    margin-bottom: 3px;
}
.Financials-heading{
    margin-top: 10px;
}
.Financials{
    float: left;
    padding-top: 5px;
    padding-left: 10px;
    width: 40%;
}
.Financials-text{
    padding-left: 140px;
    padding-top: 15px;
}
.Financials-text p{
    margin-top: 0px;
    margin-bottom: 1px;
    font-size: 12px;
}
.Financials-text h6{
    margin-bottom: 0px;
}
/* .contact-form{
    border: 1px solid #000;
    padding: 10px;
    margin-top: 20px;
} */
.contact-form-group{
    background-color: #ebebeb;
    border: 1px solid #dddddd;
    color: #c0c0c0;
}
.contact-group{
    margin-bottom: 5px;
}
.contact-group1{
    margin-top: 20px;
    margin-bottom: 5px;
}
.contact-form-text{
    margin-left: 0px;
    color: #333333;
}
.description-text h5{
    padding-top: 10px;
}
.Amenities-group h5{
    padding-top: 10px;
}
.Amenities-text{
    width: 30%;
    float: left;
}
.Amenities-text p{
    margin-top: 0px;
    margin-bottom: 3px;
}
/*--------------------------------------------------------------
#  listing end
--------------------------------------------------------------*/

.contact-form-color  { background: transparent; }
.contact-form-color input  { background: transparent; }
.contact-form-color textarea  { background: transparent; }

.carousel-status{
    display: none;
}

.carousel .thumbs-wrapper{
    margin-left: 0px !important;
    margin-top: 20px !important;
    padding: 0px;
}

.Custome-Carousel .animated {
    margin: 0px;
    padding: 0px;
}
.player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}
.custome-Listingslist-image{
    height: 200px;
}

.CurrentPropertiescustom li[data-value] {
    color: gray;
    font-size: 14px;
}
.CurrentPropertiescustom1 li[data-value="1"] {
    color: black;
    font-weight: bold;
    font-size: 16px;
}
.CurrentPropertiescustom2 li[data-value="2"] {
    color: black;
    font-weight: bold;
    font-size: 16px;
}
.CurrentPropertiescustom3 li[data-value="3"] {
    color: black;
    font-weight: bold;
    font-size: 16px;
}
.CurrentPropertiescustom4 li[data-value="4"] {
    color: black;
    font-weight: bold;
    font-size: 16px;
}
.CurrentPropertiescustom5 li[data-value="5"] {
    color: black;
    font-weight: bold;
    font-size: 16px;
}
.CurrentPropertiescustom6 li[data-value="6"] {
    color: black;
    font-weight: bold;
    font-size: 16px;
}
.CurrentPropertiescustom7 li[data-value="7"] {
    color: black;
    font-weight: bold;
    font-size: 16px;
}
.CurrentPropertiescustom98 li[data-value="8"] {
    color: black;
    font-weight: bold;
    font-size: 16px;
}

.CurrentPropertiescustom li[data-value="3"]::after
{
    content: " ⚠️";
    font-weight: bold;
    font-size: 20px;
}
.current-properties-custom .modal-content{
    background: white;
}


/*!* Center the loader *!
#loader {
    position: absolute;
    left: 50%;
    top: 35%;
    z-index: 1;
    width: 100px;
    height: 100px;
    background:url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDIwMDEwOTA0Ly9FTiIKICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4wIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiB3aWR0aD0iNzU1LjAwMDAwMHB0IiBoZWlnaHQ9Ijc0MC4wMDAwMDBwdCIgdmlld0JveD0iMCAwIDc1NS4wMDAwMDAgNzQwLjAwMDAwMCIKIHByZXNlcnZlQXNwZWN0UmF0aW89InhNaWRZTWlkIG1lZXQiPgo8bWV0YWRhdGE+CkNyZWF0ZWQgYnkgcG90cmFjZSAxLjE2LCB3cml0dGVuIGJ5IFBldGVyIFNlbGluZ2VyIDIwMDEtMjAxOQo8L21ldGFkYXRhPgo8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCw3NDAuMDAwMDAwKSBzY2FsZSgwLjEwMDAwMCwtMC4xMDAwMDApIgpmaWxsPSIjMDAwMDAwIiBzdHJva2U9Im5vbmUiPgo8cGF0aCBkPSJNMjE4MCA1Mzk2IGMtMzIwIC0xMzkgLTU2MSAtMzk3IC02NTggLTcwNiAtNDEgLTEzMiAtNDcgLTE3MyAtNDcKLTM0MCAwIC0xNDkgMyAtMTc1IDI4IC0yNjQgMTEzIC00MDkgMzY5IC02NzMgNzU3IC03ODIgbDg1IC0yNCAxNDE3IDAgYzEyMTMKMCAxNDI3IDIgMTQ4NyAxNSAyMTIgNDQgMzgyIDEzNSA1MjYgMjgwIDIxMiAyMTEgMzI1IDUxNyAzMTIgODQwIC0yMCA0NjYKLTMyNyA4NjEgLTc3NCA5OTQgLTY4IDIwIC05MiAyMSAtNjgyIDIxIGwtNjExIDAgMCAtMTkzIGMwIC0xMDcgMyAtNDI0IDcKLTcwNSBsNiAtNTEyIDI1MiAwIDI1MiAwIDYgMTg4IGM0IDEwMyA3IDMwMCA3IDQzOCBsMCAyNTEgMjc5IDggYzI0NiA2IDI4NSA1CjMzNyAtMTAgMTQ5IC00MyAyOTEgLTE1MiAzNDYgLTI2NSAxMTIgLTIzMiA3OSAtNDk2IC04MSAtNjU5IC02NSAtNjcgLTE2OQotMTMwIC0yNjEgLTE2MSBsLTY1IC0yMSAtMTM0NSA2IC0xMzQ1IDcgLTU4IDI0IGMtNzggMzEgLTE4OCAxMDYgLTIzMyAxNTcKLTQ4IDU2IC05MCAxMzAgLTExMSAxOTcgLTIyIDcwIC0yNSAyNzYgLTQgMzM1IDYzIDE4NSAxODYgMzA2IDM2OCAzNjMgNjYgMjEKODUgMjIgMzQ0IDIwIGwyNzQgLTMgMyAtNDMyIDIgLTQzMyAyNjggMCAyNjkgMCA2IDMzOCBjNCAxODUgNyA1MDAgNyA3MDAgbDAKMzYyIC02NDcgLTEgLTY0OCAwIC03NSAtMzN6Ii8+CjxwYXRoIGQ9Ik0zMDIwIDI2MzMgYzAgLTIxNCAtMyAtNDUyIC03IC01MzAgbC02IC0xNDMgNzcxIDAgNzcyIDAgMCAyNzMgYzAKMTUxIC0zIDM4OSAtNyA1MzAgbC02IDI1NyAtMjU5IDAgLTI1OCAwIDAgLTI2NSAwIC0yNjUgLTIzMiAyIC0yMzMgMyAtMiAxNDAKYy0yIDc3IC02IDE5NSAtOSAyNjMgbC02IDEyMiAtMjU5IDAgLTI1OSAwIDAgLTM4N3oiLz4KPC9nPgo8L3N2Zz4K") no-repeat scroll right center #e8e8e8;
    background-size: 100px 100px;
    border-radius: 50%;
}
#loaderdiv {
    position: absolute;
    left: 0%;
    top: 00%;
    z-index: 10000000;
    width: 100%;
    height: 100%;
    background: rgba(16, 23, 26, 0.45);
}*/

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.control-dots{
    display: none;
}
.afterprofiletext{
    height: 153px;
}
.afterprofiletext2 {
    height: 150px;
}
@media (max-width: 1440px) {
    .afterprofiletext{
        height: 162px;
    }
}

@media (min-device-width : 2560px) and (max-device-width : 2560px) {
    .afterprofiletext{
        height: 101px;
    }
}

.example-enter {
    opacity: 0.04;
}
.example-enter.example-enter-active {
    opacity: 5;
    transition: opacity 50s ease-in;
}
.example-leave {
    opacity: 1;
}
.example-leave.example-leave-active {
    opacity: 0.04;
    transition: opacity 50s ease-in;
}
.fixed-top-class .logo img {
    width: 100%;
    max-width: 175px;
    height: auto;
}
.contact-form-color .contact-group{
    margin-bottom: 7px;
}
.contact-form-color .contact-group1{
    margin-top: 7px;
    margin-bottom: 7px;
}
.contact-form-color .contact-form-group{
    height: 30px;
}
.contact-form-color .contact-group textarea {
    height: 60px;
}
.investwithushight{
    height: 150px;
}
.address-details.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: yellow;
    padding: 50px;
    font-size: 20px;
}