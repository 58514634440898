@media screen and (max-width: 425px) {

    .fixed-top-class .logo img{
        width: 67%;
    }

    #hero a samp{
        left: 41%;
    }
}
.modal-dialog-centered{
    @media screen and (max-width: 425px){
        .modal-body .modal-body{
        padding: 0px 0px;
        margin: 20px 0px;
    }
    }
}

.space {
    @media screen and (max-width: 768px) {
        .maindiv {
            max-width: unset;

            h6 {
                white-space: unset;
                font-size: 20px;
            }

            h5 {
                white-space: unset;
                font-size: 20px;
            }

            h3 {
                white-space: unset;
                font-size: 35px !important;
            }

        }
    }

    @media screen and (max-width: 320px) {
        .maindiv {
            max-width: unset;

            h6 {
                white-space: unset;
                font-size: 15px;
            }

            h5 {
                white-space: unset;
                font-size: 15px;
            }

            h3 {
                white-space: unset;
                font-size: 20px !important;
            }
            height:220px

        }
    }
    .pointercss{
        margin-left: 0px !important;
    }
}
.space-otherpage-top
{
    @media screen and (max-width: 768px) {
        .block-about {
            min-height: 63vh;
            margin: 14px;
        }
    }
}
.space-otherpage-center1{
    @media screen and (max-width: 768px) {
        img{
            width: 99%;
            margin-top: 5px;
        }
    }
}
